import {Injectable} from '@angular/core';
import {EnvironmentService} from './environment.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {TemplateTypes} from '../models/template-types.enum';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  templatesDir = 'assets/templates';

  constructor(private _environmentService: EnvironmentService,
              private _http: HttpClient) {
  }

  httpCache = new Map<string, Observable<any>>();

  public isIstitutional(url: string) : boolean {
    return (
      url.includes('geotn.it') ||
      url.includes('wtstore.it') ||
      url.includes('welcometravelshop.it') ||
      url.includes('vacanzewelcometravel.it')
    )
  }

  getTemplate(template: TemplateTypes): Observable<string> {
    const url = `${this.templatesDir}/${template}.html`;
    console.log('url', url);
    if (!this.httpCache.get(url)) {
      this.httpCache.set(url, this._http.get(url, {responseType: 'text'}).pipe(
        shareReplay({bufferSize: 1, refCount: true})
      ));
    }
    return this.httpCache.get(url);
  }
}
