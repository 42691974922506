import { Pipe, PipeTransform } from '@angular/core';
import { ICruiseDetailCabin } from '../../booking-flow/cruise/cruise-detail-page/interfaces/icruise-detail-cabin';
import { PROMO_CODES } from '../../../../assets/data/promo-codes';

@Pipe({
  name: 'filterByPromo'
})
export class FilterByPromoPipe implements PipeTransform {
  transform(promoKeys: string[], cabin: ICruiseDetailCabin): string[] {
    if (!promoKeys?.length || !cabin) {
      return [];
    }

    return promoKeys.filter(promoKey => {
      const promoConfig = PROMO_CODES[promoKey];
      if (!promoConfig) {
        return false;
      }

      return promoConfig.activeFares.includes(cabin.fare);
    });
  }
}
