export const PROMO_CODES = {
  BLACK_FRIDAY: {
    ids: [
      // 'PA02241101', 'SM07241101', 'DI14241103', 'TO07241107', 'SM07241108', 'FO03241109', 'FO05241109',
      // 'TO07241109', 'FA02241110', 'FA03241110', 'FO04241110', 'SM05241110', 'FA02241111', 'FA23241113',
      // 'FA23241113', 'FO04241114', 'TO07241114', 'TO07241116', 'DI13241117', 'FO02241118', 'FO03241118',
      // 'FO02241119', 'SM13241121', 'SM30241121', 'TO07241121', 'TO07241122', 'TO07241123', 'TO07241128',
      // 'TO07241129', 'DE03241130', 'DI05241130', 'DI14241130', 'DI15241130', 'DI18241130', 'DI18241130',
      // 'DI20241130', 'DI21241130', 'DI21241130', 'TO07241130', 'FS04241201', 'DE04241203', 'SM17241204',
      // 'SM17241204', 'FS14241205', 'FS17241205', 'FS21241205', 'FS24241205', 'TO07241205', 'FO07241206',
      // 'TO07241206', 'TO07241207', 'TO07241212', 'FO07241213', 'TO07241214', 'TO07241219', 'FO07241220',
      // 'TO07241220', 'SM07241221', 'TO07241221', 'TO07241226', 'FO14241227', 'FS07241229', 'FS14241229',
      // 'FS07241229', 'FO14250103', 'TO07250103', 'TO07250104', 'FS07250105', 'FO07250110', 'TO07250110',
      // 'TO07250111', 'FS07250112', 'TO07250116', 'TO07250117', 'TO07250118', 'SM07250118', 'FS07250119',
      // 'TO07250123', 'FO07250124', 'TO07250124', 'TO07250125', 'SM07250125', 'FS07250126', 'TO07250130',
      // 'FO07250131', 'FO14250131', 'TO07250131', 'SM07250201', 'TO07250201', 'FS07250202', 'FO07250207',
      // 'FO14250207', 'FS07250209', 'FO07250214', 'FS07250216', 'FS14250216', 'TO06250216', 'TO07250220',
      // 'TO07250222', 'FS07250223', 'FS14250223', 'TO07250227', 'FO07250228', 'FO14250228', 'TO07250228',
      // 'SM17250301', 'SM31250301', 'SM37250301', 'SM37250301', 'TO07250306', 'FO07250307', 'TO07250308',
      // 'FS07250309', 'FS14250309', 'TO07250313', 'FO16250314', 'FO16250314', 'TO07250314', 'TO07250315',
      // 'FS07250316', 'SM14250318', 'TO07250320', 'TO07250322', 'FS15250323', 'FS15250323', 'PA20250326',
      // 'PA20250326', 'PA19250327', 'PA19250327', 'FA20250329', 'FA20250329', 'PA17250329', 'TO07250329',
      // 'FO10250330', 'PA15250331', 'FA17250401', 'FA17250401', 'TO07250403', 'TO07250404', 'TO07250405',
      // 'SM04250407', 'TO07250410', 'TO07250411', 'FS05250413', 'SM07250415', 'DE03250416', 'SM07250416',
      // 'TO08250417', 'FO11250419', 'TO08250419', 'DI26250420', 'DI26250420', 'DI27250420', 'DI27250420',
      // 'DI25250421', 'FA04250421', 'SM07250422', 'DI23250423', 'FS04250423', 'SM07250424', 'FA03250425',
      // 'PA07250425', 'TO07250425', 'TO07250426', 'FS03250427', 'TO07250427', 'FA03250428', 'SM07250430',
      // 'PA07250502', 'SM07250502', 'TO07250503', 'PA07250503', 'FA11250504', 'FA11250504', 'SM07250506',
      // 'SM07250507', 'SM07250508', 'PA07250509', 'SM07250509', 'PA07250510', 'TO07250510', 'FO14250511',
      // 'TO07250511', 'SM07250513', 'SM07250514', 'FA07250515', 'FA14250515', 'SM07250515', 'DI07250516',
      // 'SM07250516', 'PA07250516', 'TO07250516', 'PA07250517', 'TO07250517', 'TO07250518', 'FS15250520',
      // 'SM07250520', 'SM07250521', 'FA07250522', 'SM07250522', 'SM07250523', 'PA07250523', 'PA07250524',
      // 'TO07250525', 'SM07250528', 'FA12250529', 'FA12250529', 'SM07250529', 'FS06250601', 'FS07250601',
      // 'FO12250608', 'FO12250608', 'FO11250609', 'FO11250609'
    ],
    activeFares: ['PROMO'],
    label: 'Promo Black Friday',
    style: 'tabloid_promoBlackFriday'
  },
  BEVANDE_GRATIS: {
    ids: [92699, 93095, 93107],
    activeFares: ['myAllinc', 'myExplor'],
    label: 'Promo All Inclusive - Bevande Gratis',
    style: 'pill-tabloid--promoCosta'
  },
  COSTA_DAYS: {
    ids: [93122],
    activeFares: ['myCruise', 'myAllinc', 'myExplor'],
    label: 'Promo - Costa Days',
    style: 'pill-tabloid--promoCosta'
  },
  C_CLUB_MEMBER_DAYS: {
    ids: [93086],
    activeFares: ['myCruise', 'myAllinc', 'myExplor'],
    label: 'C|CLUB MEMBER DAYS',
    style: 'pill-tabloid--promoCosta'
  }
};
