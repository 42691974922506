import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  // FIXME: agganciare i puntamenti delle api all'environment
  private readonly api2 = "https://api2.offertetouroperator.com/oto-api";
  private readonly networkApikey = "db7828797eca9b2d01a7b56f1d0c900468b0ca41";
  private readonly cardApiKey = "eba9b9ee-e63b-4ae8-a3b2-26315e8908eb";
  private api: string = `${this.api2}/agency/nauth/${this.networkApikey}`;
  private cardApi = `${environment.otoApi2Url}/oto-api/programme-schedule/programmeSchedule/nauth/${this.cardApiKey}/card/`
  //private readonly api2dev = "https://api2dev.offertetouroperator.com/oto-api";
  //private cardApiDev = `${this.api2dev}/programme-schedule/programmeSchedule/nauth/${this.cardApiKey}/card/`
  private legacyCmsApi: string = "https://api.offertetouroperator.com/index.php/cms/a4b5a27b37e815d85ee43db200c683a96a64ec49";
  public agencyInfo$: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient
  ) {
    this.agencyInfo$ = new BehaviorSubject<any>(null);

  }

  public getAgencyInfo(agencyId: number | string, networkId: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      const api = `${this.api}/agencyInfoByExternalId/${agencyId}/networkId/${networkId}`;

      this._http.get(api).toPromise().then(
        (resolved: any) => {
          if (resolved.error) {
            reject("ERROR");
          } else {
            resolve(resolved.result);
          }
        },
        (rejected) => {
          reject("INTERNAL ERROR");
        }
      );
    })
  }

  public getCustomContent(contentId: number): Promise<any> {
    return new Promise((resolve, reject)=> {
      // 6f1acbbc7dcaaeb661a830cff061f361d0b7bd24
      const api = `${this.legacyCmsApi}/link/id/${contentId}`;

      this._http.get(api).toPromise().then(
        (resolved: any) => {
          if (!resolved.content) {
            reject("ERROR");
          } else {
            resolve(JSON.parse(resolved.content));
          }
        },
        (rejected) => {
          reject("INTERNAL ERROR");
        }
      );
    });
  }

  public getSocialCardById(cardId: number) {
    const api = `${this.cardApi}${cardId}`;
    return this._http.get(api).pipe(
      map((x: any) => x.result.readProgrammeScheduleCardByIdResponse),
      catchError(e => {
        console.error("Cannot get card by ID");
        return of(null);
      })
    );
  }

  public getAgencyByExternalId(agencyId: number | string, networkId: number | string) {
    const api = `${this.api}/agencyInfoByExternalId/${agencyId}/networkId/${networkId}`;
    return this._http.get(api).pipe(
      map((x: any) => x.result),
      catchError(e => {
        console.error("Cannot agency info by external ID");
        return of(null);
      })
    );
  }



}
