<div class="container-fluid p-0">
  <nav class="navbar navbar-expand-lg main-nav">
    <div class="container container-lg">
      <a class="navbar-brand main-nav__brand" (click)="goHome()">
        <picture *ngIf="profile?.logo">
          <img [src]="profile?.logo" [alt]="profile?.denominazione">
        </picture>
      </a>

      <div class="navbar-wrap" #navbar>
        <div class="menu-btn" (mousedown)="toggle()">
          <div class="menu-btn__burger"></div>
        </div>
        <div class="" id="navbarMainNavDropdown" [ngClass]="{'sideBarIn': menuOpen}">
          <div class="container" [ngClass]="{'container-wtg-header' : brand?.networkId === networks.WTG}">
            <ul class="navbar-nav ms-lg-auto" [ngClass]="{'mt-1 mb-2' : brand?.networkId === networks.WTG}">
              <li class="nav-item main-nav-item">
                <a class="nav-link main-nav-item__link" [routerLink]="'chi-siamo'" routerLinkActive="active" tabindex="-1" (click)="toggle()" >Chi siamo</a>
              </li>
              <li class="nav-item main-nav-item" *ngIf="AmezingCalendarLink">
                <a class="nav-link main-nav-item__link" target="{{AmezingCalendarLink.target}}" href="{{AmezingCalendarLink.path}}" tabindex="-1"  (click)="toggle()">{{AmezingCalendarLink.title}}</a>
              </li>
              <li class="nav-item main-nav-item" *ngIf="hasPropostePage">
                <a class="nav-link main-nav-item__link" [routerLink]="'le-nostre-proposte'" routerLinkActive="active" tabindex="-1" (click)="toggle()" >Le Nostre Proposte</a>
              </li>
              <li class="nav-item main-nav-item" *ngIf="hasConad">
                <a class="nav-link main-nav-item__link" [routerLink]="'conad'" routerLinkActive="active" tabindex="-1" (click)="toggle()" >Conad</a>
              </li>
              <li class="nav-item main-nav-item">
                <a class="nav-link main-nav-item__link" [routerLink]="'news'" routerLinkActive="active" tabindex="-1" (click)="toggle()" >News</a>
              </li>
              <ng-container *ngIf="this.customLinks">
                <ng-container *ngFor="let link of this.customLinks">
                  <ng-container *ngIf="!link.items">
                    <li class="nav-item main-nav-item">
                      <a class="nav-link main-nav-item__link" target="{{link.target}}" href="{{link.path}}" tabindex="-1"  (click)="toggle()">{{link.title}}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="link.items">
                    <li class="nav-item main-nav-item" ngbDropdown>
                      <a class="nav-link main-nav-item__link" ngbDropdownToggle tabindex="-1">{{link.title}}</a>
                      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                        <a ngbDropdownItem *ngFor="let item of link.items" target="{{item.target}}" href="{{item.path}}" (click)="toggle()" >{{item.title}}</a>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>

              </ng-container>
              <!-- Necessario per gestire l'attivazione automatica del servizio liste, e non andare in conflitto con la versione di attivazione legacy -->
              <ng-container *ngIf="brand?.networkId === networks.WTG && hasListe && !this.isEnabledListeInViaggioLegacy">
                <li class="nav-item main-nav-item">
                  <a class="nav-link main-nav-item__link" target="_blank" href="{{this.listeInViaggioUrl}}" tabindex="-1" (click)="toggle()">Liste Eventi</a>
                </li>
              </ng-container>
              <ng-container *ngIf="!this.isNetworkSite && brand?.networkId === networks.GEO && showDropdownContentInNavlinkLevel">
                <li class="nav-item main-nav-item">
                  <a *ngIf="hasNewsletter" class="nav-link main-nav-item__link" [routerLink]="'newsletter'" (click)="toggle()" >Newsletter</a>
                </li>
                <li class="nav-item main-nav-item">
                  <a *ngIf="hasListe" class="nav-link main-nav-item__link" [routerLink]="'liste-eventi'" tabindex="-1" (click)="toggle()">Liste Eventi</a>
                </li>
              </ng-container>
              <li class="nav-item main-nav-item" ngbDropdown *ngIf="!this.isNetworkSite && brand?.networkId === networks.GEO && !showDropdownContentInNavlinkLevel">
                <a class="nav-link main-nav-item__link" ngbDropdownToggle tabindex="-1">Servizi</a>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                  <a ngbDropdownItem *ngIf="hasNewsletter" [routerLink]="'newsletter'" (click)="toggle()" >Newsletter</a>
                  <a ngbDropdownItem *ngIf="hasListe" [routerLink]="'liste-eventi'" (click)="toggle()">Liste {{isBCV ? "in Viaggio" : "Eventi"}}</a>
                  <a ngbDropdownItem *ngIf="isBCV" [routerLink]="'offerte/idee-regalo'" (click)="toggle()">Idee Regalo</a>
                </div>
              </li>

              <!-- Liste in Viaggio -->
              <li class="nav-item main-nav-item" *ngIf="brand?.networkId === networks.WTG && isEnabledListeInViaggioLegacy">
                <a class="nav-link main-nav-item__link" [href]="sanitize(listeInViaggioUrl)" target="_blank" tabindex="-1" >{{listeInViaggioLabel}}</a>
              </li>

              <!-- StoreLocator -->
              <li class="nav-item main-nav-item" *ngIf="isNetworkSite || hasMultipleStores">
                <a class="nav-link main-nav-item__link" [routerLink]="'cerca-agenzia'" routerLinkActive="active" tabindex="-1" (click)="toggle()">Cerca Agenzia</a>
              </li>

              <!-- FLASH VIAGGI CUSTOM PAGE -->
              <li class="nav-item main-nav-item" *ngIf="isFlashViaggiAgency">
                <a class="nav-link main-nav-item__link" [routerLink]="'convenzioni.html'" routerLinkActive="active" tabindex="-1">Convenzioni</a>
              </li>

              <!-- Contatti -->
              <li class="nav-item main-nav-item" *ngIf="!isNetworkSite && !hasMultipleStores">
                <a class="nav-link main-nav-item__link" [routerLink]="'contatti'" routerLinkActive="active" tabindex="-1" (click)="toggle()">Contatti</a>
              </li>
              <!-- Lavora con noi-->
              <li class="nav-item main-nav-item" *ngIf="isWtgNetworkSite">
                <a class="nav-link main-nav-item__link" [routerLink]="'lavora-con-noi'" routerLinkActive="active" tabindex="-1" (click)="toggle()" >Lavora con noi</a>
              </li>
            </ul>
            <nav class="navbar navbar-expand-lg ms-lg-auto navbar--socials"
                 *ngIf="brand?.networkId === networks.WTG">
              <ul *ngIf="!isWTShop && !isWTStore && !isWtgNetworkSite" class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--contacts">
                <li *ngIf="profile?.email_1?.trim() || profile?.email_2?.trim()" class="nav-item">
                  <a class="nav-link nav-item__link"
                     tabindex="-1"
                     [href]="'mailto:' + profile?.email_1.trim() || profile?.email_2.trim()"
                     aria-disabled="true">
                    <i class="icon-email"></i>
                    <span class="nav-link__info">
                      <small>Email:</small>
                      {{profile?.email_1.trim() || profile?.email_2.trim()}}
                    </span>
                  </a>
                </li>
                <li *ngIf="profile?.tel_1?.trim() || profile?.tel_2?.trim()" class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded">
                  <a [href]="'tel:' + profile?.tel_1.trim() || profile?.tel_2.trim()" class="nav-link nav-item__link">
                    <i class="icon-phone"></i>
                    <span class="nav-link__info">
                      <small>Tel:</small>
                      {{profile?.tel_1.trim() || profile?.tel_2.trim()}}
                    </span>
                  </a>
                </li>
              </ul>
              <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--socials">
                <li class="nav-item" *ngIf="profile?.whatsapp?.trim()">
                  <a target="_blank" [href]="sanitize((isMobile$ | async) ? 'whatsapp://send?phone=' + (profile?.whatsapp?.trim()) : 'https://wa.me/' + (profile?.whatsapp?.trim()))" class="navbar-brand nav-item__ico-social social-icon">
                    <i class="icon-whatsapp"></i>
                  </a>
                </li>
                <li class="nav-item" *ngIf="profile?.facebook?.trim()">
                  <a [href]="profile?.facebook.trim()" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
                    <i class="icon-facebook"></i>
                  </a>
                </li>
                <li class="nav-item" *ngIf="profile?.twitter?.trim()">
                  <a [href]="profile?.twitter.trim()" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
                    <i class="icon-twitter"></i>
                  </a>
                </li>
                <li class="nav-item" *ngIf="profile?.youtube?.trim()">
                  <a [href]="profile?.youtube.trim()" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
                    <i class="icon-youtube"></i>
                  </a>
                </li>
                <li class="nav-item" *ngIf="profile?.instagram?.trim()">
                  <a [href]="profile?.instagram.trim()" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
                    <i class="icon-instagram"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="logo-geo d-none d-lg-block" *ngIf="!isNetworkSite && brand?.networkId === networks.GEO">
        <span>Agenzia partner di:</span>
        <img *ngIf="brand?.networkId === networks.GEO" src="./assets/images/geo_logo.png" height="40" alt="GEO Travel Network">
      </div>
    </div>
  </nav>
</div>
