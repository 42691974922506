import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import fixArray from '../../../../../utils/fixArray';
import maxBy from '../../../../../utils/maxBy';
import { ICruiseSpecial } from '../../cruise-search-page/interfaces/icruise-special';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { ProjectService } from '../../../../shared/services/project.service';
import {CabinsByTypePipe} from '../../../../shared/pipes/cabins-by-type.pipe';
import { ICruiseDetail } from '../interfaces/icruise-detail';
import { ICruiseDetailCabin } from '../interfaces/icruise-detail-cabin';
import { ICruiseDetailExcursion } from '../interfaces/icruise-detail-excursion';
import { ICruiseDetailParams } from '../interfaces/icruise-detail-params';
import { ICruiseDetailSolution } from '../interfaces/icruise-detail-solution';
import { ICruiseDetailStep, InfoStep } from '../interfaces/icruise-detail-step';
import { IShipServiceParams } from '../interfaces/iship-service-params';
import { IShipServiceRemote } from '../interfaces/iship-service-remote';
import { ICruiseDetailCabinByType } from "../interfaces/icruise-detail-cabin-by-type";
import { CruiseTopId } from '../../../../shared/models/cruise-top-id.enum';
import { ICruiseSolution } from "../../cruise-search-page/interfaces/icruise-solution";
import { PROMO_CODES } from '../../../../../../assets/data/promo-codes';
interface PromoCheckResult {
  isActive: boolean;
  promoKey: string;
}
@Injectable({
  providedIn: 'root'
})

export class CruiseDetailService {

  constructor(private _http: HttpClient,
    private _projectService: ProjectService,
    private _environmentService: EnvironmentService,
    private _cabinsByType: CabinsByTypePipe) {
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  //#region utils step formatters
  private formatCruiseHour(hour: string) {
    //  A volte gli orari, quando non disponibili, vengono passati con la stringa '-'. In questi casi li setto a null per uniformità
    if(hour.length < 2) return null;
    const noSpaceHour = hour.trim();
    return noSpaceHour.slice(0,2) + ":" + noSpaceHour.slice(2);
  }

  private insertStep(newSteps: ICruiseDetailStep[], currentStepInfo: InfoStep, accessKey: 'arrivalHour' | 'departureHour') {
    const indexDay = newSteps.findIndex(fixStep => fixStep.day === currentStepInfo.day);

    if(indexDay !== -1) {
      const harboursOnFix = newSteps[indexDay].harbours;
      const harbourFix = harboursOnFix.find(harb => harb.data.Code === currentStepInfo.harbour.Code);
      // se il porto viene trovato setto l'ora
      if(!!harbourFix) {
        harbourFix[accessKey] = currentStepInfo.hour;
      } else {
        // altrimenti creo l'oggetto e lo pusho
        harboursOnFix.push({
          data: currentStepInfo.harbour,
          arrivalHour: accessKey === 'arrivalHour' ? currentStepInfo.hour : null,
          departureHour: accessKey === 'departureHour' ? currentStepInfo.hour : null
        });
      }
    } else {
      const firstHarbour = {
        arrivalHour: accessKey === 'arrivalHour' ? currentStepInfo.hour : null,
        departureHour: accessKey === 'departureHour' ? currentStepInfo.hour : null,
        data: currentStepInfo.harbour
      };
      newSteps.push({
        day: currentStepInfo.day,
        isNavigation: false,
        harbours: [ firstHarbour ]
      });
    }
  }
  //#endregion

  private getFormattedCruiseName(cruiseTopId: number, cruiseName: string): string {
    // se è una crociera carnival, rimuovo i caratteri speciali dalla stringa
    if (cruiseTopId === CruiseTopId.CARNIVAL) {
      const itineraryString = cruiseName;
      const withoutSquareBrackets = itineraryString.replace(/\[(.*?)\]/g, '');
      const withoutDestination = withoutSquareBrackets.replace(/#.+/g, '');
      const finalItineraryString = withoutDestination;

      return finalItineraryString;
    }

    return cruiseName;
  }

  formatExcursion(excursion: any): ICruiseDetailExcursion {
    return {
      code: excursion.Code,
      description: excursion.Description,
      harbour: { name: excursion.Harbour.Name, id: excursion.Harbour.Id, code: excursion.Harbour.Code },
      duration: excursion.Duration,
      id: excursion.Id,
      level: excursion.Level,
      tags: excursion.Typology?.split(';').map(e => e.trim()),
      title: excursion.Title
    };
  }

  formatCabin(cabin: any): ICruiseDetailCabin {
    return {
      availability: cabin.Availability === 1,
      available: cabin.Available === 1,
      code: cabin.Code,
      description: this.setCabinDescription(cabin) || cabin.Description,
      fare: cabin.Fare,
      fareName: cabin.FareName,
      fsb: parseFloat(cabin.Fsb),
      id: parseInt(cabin.Id, 10),
      price: parseFloat(cabin.MKPriceCP),
      taxes: parseFloat(cabin.Taxes),
      type: cabin.Type,
      isUpdatedFromCategory: false
    };
  }

  setCabinDescription(cabin) {
    let description = '';
    if (!cabin?.Description || cabin.Description.toLowerCase().includes('premium')) {
      description += cabin.Type;
      if (cabin?.SubCategory?.toLowerCase() !== 'premium') {
        description += " " + cabin.SubCategory;
      }
    }
    return description;
  }

  formatSolution(solution: any, cruise: ICruiseDetail): ICruiseDetailSolution {
    const arrivalDate = new Date(solution.ArrivalDate);
    const departureDate = new Date(solution.DepartureDate);
    const days = (dayjs(arrivalDate).diff(departureDate, 'days')) + 1;
    const cabins = solution.Cabin_Collection?.Cabin ?
        fixArray(solution.Cabin_Collection.Cabin).map(e => this.formatCabin(e)) : [];

    return {
      arrivalDate,
      arrivalHarbour: { id: solution.ArrivalHarbour.Id, name: solution.ArrivalHarbour.Name },
      cabins,
      code: solution.Code,
      days,
      departureDate,
      departureHarbour: { id: solution.DepartureHarbour.Id, name: solution.DepartureHarbour.Name },
      excursions: solution.Excursions_Collection?.CruiseExcursion
        ? fixArray(solution.Excursions_Collection.CruiseExcursion).map(e => this.formatExcursion(e)) : undefined,
      hasFlight: solution.FlightMandatory === 1,
      id: solution.Id,
      price: parseFloat(solution.MKPriceCP),
      source: solution.Source,
      specials: solution.Specials_Collection.Special
          ? fixArray(solution.Specials_Collection.Special).map(e => this.formatSpecial(e)) : [],
      cabinsGroupedByType: this.getCruiseCabinsGroupedByType(cabins, solution, cruise),
      isPriceUpdatedFromCategory: false
    };
  }

  fillSteps(steps: ICruiseDetailStep[]): ICruiseDetailStep[] {
    if (!steps || (steps && steps.length === 0)) {
      return [];
    }
    const firstDay = 1;
    const lastDay = maxBy(steps, 'day').day;
    for (let i = firstDay; i <= lastDay; i++) {
      const currentDay = steps.find(x => x.day === i);
      if (!currentDay) {
        steps.push({
          day: i,
          isNavigation: true,
          harbours: []
        });
      }
    }
    return steps.sort((a, b) => a.day - b.day);
  }

  formatSpecial(special: any): ICruiseSpecial {
    return {
      id: special.Id,
      type: special.Type
    };
  }

  formatSteps(steps: any[]) {
    const fixedSteps: ICruiseDetailStep[] = [];

    steps.forEach(step => {
      const depCurrentStep = {
        day: Number(step.DepartureDay), harbour: step.DepartureHarbour, hour: this.formatCruiseHour(step.DepartureHour)
      };
      const arrCurrentStep = {
        day: Number(step.ArrivalDay), harbour: step.ArrivalHarbour, hour: this.formatCruiseHour(step.ArrivalHour)
      };
      // setto le partenze di tutti i porti
      this.insertStep(fixedSteps, depCurrentStep, 'departureHour');
      // setto gli arrivi di tutti i porti
      this.insertStep(fixedSteps, arrCurrentStep, 'arrivalHour');
    });

    return fixedSteps;
  }

  formatCruise(cruise: any): ICruiseDetail {
    if (!cruise?.Solution_Collection) return null;

    const solutions = cruise.Solution_Collection.CruiseSolution ?
      fixArray(cruise.Solution_Collection.CruiseSolution).map(e => this.formatSolution(e, cruise)) : [];
    const steps = cruise.Steps_Detail.Step ? this.formatSteps(fixArray(cruise.Steps_Detail.Step)) : [];

    return {
      selectedSolution: undefined,
      code: cruise.Code,
      id: cruise.Id,
      name: this.getFormattedCruiseName(cruise.TourOperator?.Id, cruise.Name),
      parentName: cruise.Itinerary_Detail.ParentName,
      seller: cruise.Seller,
      solutions,
      source: cruise.Source,
      steps: this.fillSteps(steps),
      ship: { name: cruise.Ship.Name, id: cruise.Ship.Id, code: cruise.Ship.Code, image: cruise.Ship.Image },
      tourOperator: { name: cruise.TourOperator.Name, id: cruise.TourOperator.Id }
    };
  }

  getFormattedCruiseDetail(searchParams: ICruiseDetailParams, isLanding?): Observable<ICruiseDetail> {
    return (
      searchParams.from ?
        this.getCruiseDetail(searchParams, isLanding) :
        this.getGenericCruiseDetail(searchParams, isLanding)
    )
      .pipe(
        map(e => this.formatCruise(e)),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
      );
  }

  //  Metodo separato per dettaglio generico
  // TODO Idealmente sarebbe meglio uniformare a getCruiseDetail ma si rimanda questo sviluppo
  // a quando la ricerca generica con queryParams sarà rodata e saranno più chiari effettivi requisiti
  getGenericCruiseDetail(searchParams: ICruiseDetailParams, isLanding: boolean): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const apikey = isLanding != undefined && isLanding == true ? 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb' : e[1];
        const url = `${e[0].searchUrl}/index.php/cruise/${apikey}/details/${searchParams.source}/groupfilter/${searchParams.network}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.Cruise),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }


  getCruiseDetail(searchParams: ICruiseDetailParams, isLanding: boolean): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
      this._projectService.hasOnlyNetQuotes(),
      this._projectService.getQuoteType()
    ]).pipe(
      mergeMap(e => {
        const hasOnlyNetQuotes = e[2];
        const quoteType = e[3];
        const apikey = isLanding != undefined && isLanding == true ? 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb' : e[1];
        const url = `${e[0].searchUrl}/index.php/cruise/${apikey}/details/${searchParams.source}/from/${searchParams.from}/to/${searchParams.to}/groupfilter/${searchParams.network}/onlyNetworkQuotes/${hasOnlyNetQuotes}/quoteType/${quoteType}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.Cruise),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getShipService(searchParams: IShipServiceParams): Observable<IShipServiceRemote> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiShipServiceUrl}/index.php/cruise/${e[1]}/shipservice/${searchParams.tourOperatorId}/${searchParams.shipCode}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.result),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCruiseCabinsGroupedByType(cabins: ICruiseDetailCabin[], solution: any, cruise: ICruiseDetail): ICruiseDetailCabinByType[] {
    let availableCabins = JSON.parse(JSON.stringify(cabins));
    availableCabins = availableCabins.filter((cabin) => cabin.type);

    const activePromo = this.getActivePromos(cruise);
    return this._cabinsByType.transform(availableCabins, false, activePromo);
  }

  private hasAnyPromo(solution: any): boolean {
    const allPromoIds = Object.values(PROMO_CODES)
      .flatMap(promo => promo.ids);

    return Array.isArray(solution.specials)
      ? solution.specials.some(special => allPromoIds.includes(special.id))
      : allPromoIds.includes(solution.Specials_Collection?.Special?.Id);
  }

  private checkPromos(cruise: ICruiseDetail): PromoCheckResult[] {
    return Object.entries(PROMO_CODES).map(([key, promo]) => ({
      isActive: this.isPromoActive(cruise, promo.ids),
      promoKey: key
    }));
  }

  public getActivePromos(cruise: ICruiseDetail): string[] {
    return this.checkPromos(cruise)
      .filter(result => result.isActive)
      .map(result => result.promoKey);
  }

  private isPromoActive(cruise: ICruiseDetail, promoIds: (number | string)[]): boolean {
    return cruise.solutions?.some((solution) => {
      if (typeof promoIds[0] === 'string') {
        return promoIds.includes(solution.code);
      }
      return solution.specials?.some(special => promoIds.includes(special.id));
    });
  }
}
