export function sortCityCodes(cityCodes: string[] | string): string[] | string {
  if (!cityCodes || cityCodes.length === 0) {
    return [];
  }
  if (typeof cityCodes === 'string') {
    return cityCodes;
  }
  return cityCodes.sort((a, b) => {
    if (a === "MXP") return -1;
    if (b === "MXP") return 1;
    if (a === "FCO") return -1;
    if (b === "FCO") return 1;
    return a.localeCompare(b);
  });
}
